import React from 'react';
import { useField } from 'formik';

import FilesInput from '../FilesInput';

const FilesField = (props) => {
  const [{ value }, , { setValue }] = useField(props);

  return <FilesInput value={value} onChange={setValue} {...props} />;
};

export default FilesField;
