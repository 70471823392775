import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import ButtonFilePicker from '../ButtonFilePicker';

const useStyles = makeStyles((theme) => ({
  label: {
    color: ({ disabled }) =>
      disabled ? theme.palette.text.disabled : undefined,
  },
}));

const FilesInput = ({
  label,
  disabled,
  value,
  onChange,
  renderValue,
  ...restProps
}) => {
  const classes = useStyles({ disabled });

  const handleSelect = (files) => {
    if (files.length > 0) {
      onChange([...value, ...files]);
    }
  };

  const removeFile = (index) =>
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);

  return (
    <div>
      {label && (
        <Typography className={classes.label} gutterBottom>
          {label}
        </Typography>
      )}
      {renderValue({ value, utils: { removeFile } })}
      <ButtonFilePicker
        disabled={disabled}
        ButtonSlimProps={{ startIcon: <AddIcon /> }}
        onSelect={handleSelect}
        {...restProps}
      >
        Pridėti
      </ButtonFilePicker>
    </div>
  );
};

FilesInput.defaultProps = {
  disabled: false,
};

FilesInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  renderValue: PropTypes.func.isRequired,
};

export default FilesInput;
